import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import HeroImg2 from '../Components/HeroImg2';
import Work from '../Components/Work';

export default function Project(props) {
  return (
    <>
    <div>
      <Navbar/>
      <HeroImg2 heading="PROJECTS" text="Here are some of my recent projets"/>
      <Work />
      <Footer/>
    </div>
    </>
  );
}
