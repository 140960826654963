import React from 'react';
import Navbar from '../Components/Navbar';
import HeroImg from '../Components/HeroImg';
import Footer from '../Components/Footer';
export default function Home() {
  return (
    <>
    <Navbar />
    <HeroImg />
    <Footer />
    </>
  );
}
