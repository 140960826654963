import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import HeroImg2 from '../Components/HeroImg2';
import Form1 from "../Components/Form1";
export default function Contact() {
  return (
    <>
    <Navbar />
    <HeroImg2 heading="CONTACT" text="Get in Touch" />
    <Form1/>
    <Footer/>
    </>
  );
}
