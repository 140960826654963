import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import HeroImg2 from '../Components/HeroImg2';
import "./About.css";
export default function About() {
  return (
    <>
    <Navbar/>
    <HeroImg2 heading="ABOUT" text= "Skills | Education | Experience "/>
    <h1 style={{textAlign:"center", textDecoration:"underline", marginBottom:"2%"}}>SKILLS</h1>
    <div className='Buttons'>
    <button className='btn'>Bootstrap</button>
    <button className='btn'>C</button>
    <button className='btn'>C++</button>
    <button className='btn'>CSS</button>
    <button className='btn'>DBMS</button>
    <button className='btn'>DSA</button>
    <button className='btn'>HTML</button>
    <button className='btn'>Java</button>
    <button className='btn'>Javascript</button>
    <button className='btn'>OS</button>
    <button className='btn'>OOP</button>
    <button className='btn'>Python</button>
    <button className='btn'>SpringBoot</button>
    <button className='btn'>SQL</button>
    </div>
    <div>
    <h1 className="education" style={{marginBottom:"2%"}}>EDUCATION</h1>
    <div className="timeline">
  <div className="container left">
    <div className="content">
      <h3 className="content2">Kalinga Institute of Industrial Technology</h3>
      <p className="place">Odisha, India</p>
     <p className="year">2018-2022</p>
     <p className="inside">Bachelor of Technology in Computer Science and Engineering CGPA: 8.8/10</p>
    </div>
  </div>
  <div className="container right">
    <div className="content">
      <h2 className="content2">Sarla Birla Public School</h2>
      <p className="place">Ranchi, India</p>
      <p className="year">2010-2018</p>
      <p className="inside">High School. (Matriculation) CBSE, (Intermediate) CBSE
</p>
    </div>
  </div>
</div>
<hr />
<h1 className="education" style={{marginBottom:"2%"}}>EXPERIENCE</h1>
<div className="timeline">
<div className="container left">
    <div className="content">
      <h3 className="content2">Highradius</h3>
      <p className="place">Hyderabad, India</p>
     <p className="year"><div className="desig">Business Analyst</div> July 2022 – December 2022</p>
     <p className="year"><div className="desig">Techno Functional Consultant – Intern</div> June 2021 – June 2022</p>
     <p className="inside">
- Preparing SRS, maintaining and enhancing EIPP products for various clients and coordinating across teams. <br/>
- Responsible for de-escalating client issues and conflict resolution for various products.<br/>
- Integrated various connectors to 3rd party modules which enhanced client productivity.<br/>
- Improved productivity of the cross-teams by preparing task trackers, resource planners & project planners.<br/> 
- Configuring environment for implementing E2E workflow of UAT, Production & Hypercare.<br/>
- Best rated product by 80% of the clients using EIPP.
     </p>
    </div>
  </div>
  <div className="container right">
    <div className="content">
      <h2 className="content2">Highradius</h2>
      <p className="place">Odisha, India</p>
      <p className="year"><div className="desig">Software Development Engineer – Intern</div> January 2021 – March 2021</p>
      <p className="inside">
      - Developed an Invoice categorizer based on predicted payment dates using ML.<br/>
      - Designed a full stack Invoice Management Application using ReactJs, JDBC, Java and JSP. <br/>
      - Achieved an accuracy of 70% on invoice categorizer<br/>
</p>
    </div>
</div>
</div>
    </div>
    <Footer/>
    </>
  );
}
