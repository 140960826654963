import React from 'react';
import "./HeroImg.css";
import IntroImg from "../Assets/derek-story-vl69QxcojR0-unsplash.jpg";
import { Link } from "react-router-dom";
export default function HomeImg() {
  return (
    <>
    <div className="hero">
        <div className="mask">
            <img className="intro-img" src={IntroImg} alt="IntroImg" />
        </div>

        <div className="contenti">
            <h1>HI, I'M NIKKI KUMARI</h1>
            <p>Determined ⚙︎ Fast learner ⚙︎ Analytical</p>
            <div>
               <Link to="/Project" className="btn">Projects</Link>
               <Link to="/Contact" className="btn btn-light">Contact</Link>
            </div>
            
        </div>
    </div>
    </>
  );
}
