import "./Workcard.css";


import React from 'react';
import { NavLink } from "react-router-dom";

export default function Workcard(props) {
  return (
    <>
   <div className="project-card">
          <img src={props.imgsrc} alt="network issue" />
          <h3 className="project-titile">{props.title}</h3>
          <div className="pro-detatils"></div>
          <p>{props.text}</p>
            <div className="pro-btns">
              <NavLink to={props.view} className="btn">
               View
              </NavLink><NavLink to={props.source} className="btn">
               Source
              </NavLink>
            </div>
        </div>
    </>
  );
}
