import pro1 from "../Assets/Img2.png";
import pro2 from "../Assets/Blue .png";
import pro3 from "../Assets/text.jpg";
import pro4 from "../Assets/Img1.png";
import pro5 from "../Assets/period.png";
const ProjectCardata=[
    {
        imgsrc: pro5,
    title: "MyMonthly",
    text: "A period tracker app which predict upcoming cycle date, next cycle end date and ovulation date using React JS, Bootstrap, HTML, CSS, Js.",
    view: "https://mymonthlyapp.kumarinikki.com",
    source: "https://github.com/nikkikumari1506/Mymonthly.git"
    },
    
    {
    imgsrc: pro3,
    title: "Text Converter Tool",
    text: " A responsive web application which manipulates texts using React JS, Bootstrap, HTML, CSS, Js.",
    view: "https://text-converter-tool.pages.dev/",
    source: "https://github.com/nikkikumari1506/Text-Conveter-Tool"
},
    {
    imgsrc: pro4,
    title: "Date prediction",
    text: "A ML model to predict payment date of invoices and bucketing it based on the dates.",
    view: "",
    source: ""
   },

   {
    imgsrc: pro1,
    title: "Invoice management application",
    text: "AI enabled fintech responsive dashboard using React, HTML, CSS, Js.",
    view: "",
    source: ""
   },

   {
    imgsrc: pro2,
    title: "LMS",
    text: "an automated library management system with book issue, return and report functionalities.",
    view: "",
    source: ""
    
   }
];

export default ProjectCardata;
