import "./Footerstyles.css";

import { FaHome, FaMailBulk, FaGithub, FaLinkedin, FaTwitter} from "react-icons/fa";
import React from 'react';

export default function Footer() {
  return (
    <>
    <div className="footer">
        <div className="footer-container">
            <div className="left">
                <div className="location">
                 <FaHome size={20} style={{color : "#fff",
             marginRight: "2rem"}} />
             <div>
                <p>Ranchi, Jharkhand</p>
                <p>India.</p>
             </div>
            </div>

            {/*<div className="Phone">
                <h4><FaPhone size={20} style={{color : "#fff", marginRight: "2rem"}} />
            +91-000-0000-000</h4>
  </div>*/}

            <div className="Email">
            <h4><FaMailBulk size={20} style={{color : "#fff", marginRight: "2rem"}} />
                <a href="mailto:nkumari.work@gmail.com">nkumari.work@gmail.com</a></h4>
            </div>

            </div>

            <div className="right">
                <h4>Imagination is more important than knowledge.</h4>
                <p className="quote">~~Albert Einstein </p>
            <div className="social">
                <a href="https://github.com/nikkikumari1506"><FaGithub size={30} style={{color : "#fff", marginRight: "1rem"}} /></a>
                <a href="https://www.linkedin.com/in/nikki-kumari-b9b42a207"><FaLinkedin size={30} style={{color : "#fff", marginRight: "1rem"}} /></a>
                <a href="https://twitter.com/NikkiKumari1506"><FaTwitter size={30} style={{color : "#fff", marginRight: "1rem"}} /></a>              
            </div>
        </div>  
     </div>  
     <hr/>
        <div className="row">
            <p className = "col-sm">
                &copy;{new Date().getFullYear()}・All rights reserved・<a href="/">Nikki Kumari</a>
            </p>
</div>
</div>
    </>
  );
}

